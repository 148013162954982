/* Container styling for ProductsPage */
.products-page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff; /* Full page background remains white */
  color: #333;
  font-family: 'Inter', sans-serif;
}

/* Pricing Container: wraps both the title and the membership cards */
.pricing-container {
  background: #e0e0e0;  /* Grey background covering entire area */
  width: 100%;
  padding: 60px 0;      /* Adjust vertical padding as needed */
}

/* Page Title */
.page-title {
  text-align: center;
  margin: 100px auto 50px;  /* No top margin since container adds vertical padding */
  max-width: 800px;
  padding: 20px;
}

.page-title h1 {
  font-size: 3rem; /* Adjust as needed */
  font-weight: 700;
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Optional for added depth */
  margin-bottom: 10px;
}

.page-title p {
  font-size: 1.5rem; /* Adjust as needed */
  font-weight: 500;
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Optional */
}

/* Pricing Section */
.pricing-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 0 40px;  /* Horizontal padding */
  max-width: 1200px;
  margin: 0 auto;
}

/* Pricing Card */
.pricing-plan {
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  color: #fff;
  padding: 40px;
  border-radius: 15px;
  transition: transform 0.3s ease, background 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}

.pricing-plan:hover {
  transform: translateY(-5px);
}

/* Typography for plan title */
.pricing-plan h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
  text-shadow: none;
}

/* Feature List - increased font size */
.pricing-plan ul {
  list-style: none;
  font-size: 1.5rem;  /* Increased from 1.25rem */
  line-height: 1.8;   /* Slightly increased for clarity */
  padding: 0;
  margin: 0 0 20px;
  flex-grow: 1;
}

.pricing-plan ul li {
  margin-bottom: 12px;
  padding-left: 1.5em;
  position: relative;
}

.pricing-plan ul li:before {
  content: "✔";  /* Custom icon */
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  font-size: 1.25rem; /* Increased slightly to match the text */
}

/* Nested list for sub-features - increased font size */
.pricing-plan ul ul {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 1.5em;
  font-size: 1.3rem; /* Increased from 1.1rem */
}

.pricing-plan ul ul li:before {
  content: "✔"; 
  color: #eee;
  font-size: 1.1rem; /* Increased slightly */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .pricing-container {
    padding: 60px 20px;
  }
  
  .page-title {
    padding: 0 20px;
    margin-bottom: 50px;
  }
  
  .page-title h1 {
    font-size: 3rem;
  }
  
  .page-title p {
    font-size: 1.5rem;
  }
  
  .pricing-section {
    padding: 0 20px;
  }
}
