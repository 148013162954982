/* accountDeletion.css */

/* Container */
.account-deletion-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: transparent;
  }
  
  /* Main Content */
  .account-deletion-content {
    margin: 120px auto;
    padding: 40px 50px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  /* Headings & Paragraphs */
  .account-deletion-content h1 {
    color: #3C0301;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .account-deletion-content p {
    color: #666;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .account-deletion-content ul {
    padding-left: 20px;
    margin-bottom: 1.5rem;
    color: #3C0301;
  }
  
  /* Form Styles */
  .account-deletion-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .account-deletion-form label {
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: #3C0301;
  }
  
  .account-deletion-form input,
  .account-deletion-form textarea {
    padding: 0.75rem 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .account-deletion-form input:focus,
  .account-deletion-form textarea:focus {
    outline: none;
    border-color: #3C0301;
  }
  
  /* Submit Button */
  .account-deletion-submit {
    padding: 0.75rem;
    background-color: #3C0301;
    border: none;
    border-radius: 25px;
    color: #fff;
    font-size: 1.125rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .account-deletion-submit:hover {
    background-color: #7D0B02;
    transform: translateY(-2px);
  }
  .account-deletion-content .app-name {
    color: #3C0301;
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 1.5rem;
}
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .account-deletion-content {
      margin: 120px auto;
      padding: 20px;
    }
    
    .account-deletion-content h1 {
      font-size: 2rem;
    }
    
    .account-deletion-content p,
    .account-deletion-form label,
    .account-deletion-form input,
    .account-deletion-form textarea,
    .account-deletion-submit {
      font-size: 0.9rem;
    }
  }
  