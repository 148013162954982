/* General Styles */
body {
  font-family: 'Inter', sans-serif;
  color: #333;
  background-color: #fff;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.privacy-policy-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Privacy Policy Content */
.privacy-policy-content {
  padding: 80px 50px;
  margin: 100px auto 0; /* centers content with top margin */
  background-color: #f8f8f8;
  color: #3C0301;
  flex-grow: 1;
  max-width: 800px; /* optional: restricts width for readability */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.privacy-policy-content h1 {
  font-size: 48px;
  text-align: center;
}

.last-updated {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

.policy-section {
  margin-top: 40px;
}

.policy-section h2 {
  font-size: 28px;
  color: #3C0301;
  margin-bottom: 10px;
}

.policy-section p,
.policy-section ul {
  font-size: 18px;
  color: #333;
}

.policy-section ul {
  list-style-type: none;
  padding-left: 0;
}

.policy-section ul li {
  padding-bottom: 10px;
  font-weight: 300;
}

.policy-section ul li::before {
  color: #3C0301;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Account Deletion Link Styles */
.policy-section a {
  color: #3C0301;
  text-decoration: underline;
  font-weight: 600;
}

.policy-section a:hover {
  color: #7D0B02;
}

/* Mobile Styles (Below 768px) */
@media (max-width: 768px) {
  .privacy-policy-content {
    padding: 40px 20px;
    margin-top: 80px;
  }

  .privacy-policy-content h1 {
    font-size: 32px;
  }

  .last-updated {
    font-size: 16px;
  }

  .policy-section h2 {
    font-size: 24px;
  }

  .policy-section p,
  .policy-section ul {
    font-size: 16px;
  }
}
