/* GetAppPage.css */

/* Page Container */
.getapp-page {
  min-height: 100vh;
  position: relative;
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  color: #fff;
}

/* Main Content */
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  text-align: center;
  min-height: 100vh;
}

/* Titles */
.page-title {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 50px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

/* Download Links */
.download-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.download-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #3C0301;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.download-button:hover {
  transform: translateY(-3px);
  background-color: #f0f0f0;
}

/* Store Icon Styling */
.store-icon {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .page-title {
    font-size: 40px;
  }
  
  .download-links {
    flex-direction: column;
  }
}