/* PricingPage Container */
.pricing-page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff; /* Full page background remains white */
  font-family: 'Inter', sans-serif;
}

/* Pricing Container: Wraps both the title and the pricing cards */
.pricing-container {
  width: 100%;
  padding: 100px 0 60px; /* Extra top padding to space from navbar */
}

/* Page Title with Gradient */
.page-title {
  text-align: center;
  margin: 0 auto 50px;
  max-width: 800px;
  padding: 20px;
}

.page-title h1 {
  font-size: 3rem;
  font-weight: 700;
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

/* Pricing Plans Container */
.pricing-plans {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); /* Cards will be at least 500px wide */
  gap: 30px;
  padding: 0 40px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Pricing Card */
.pricing-card {
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  color: #fff;
  padding: 40px;
  border-radius: 15px;
  transition: transform 0.3s ease, background 0.3s ease;
  cursor: pointer;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

/* Typography for Pricing Card Titles and Text */
.pricing-card h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.pricing-card p {
  font-size: 1.25rem;
  margin-bottom: 15px;
  line-height: 1.5;
}

/* Additional Price Text Styles */
.price-label {
  font-size: 1.1rem;
  margin-bottom: 5px;
  font-weight: bold;
}

.monthly-price,
.yearly-price {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

/* Feature List Styling */
.pricing-features {
  list-style: none;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0;
  margin: 20px 0 0;
}

.pricing-features > li {
  margin-bottom: 10px;
  padding-left: 1.5em;
  position: relative;
}

.pricing-features > li:before {
  content: "✔";
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  font-size: 1.25rem;
}

/* Remove bullet icons for nested (sub-feature) list items */
.pricing-features ul li:before {
  content: "";
  display: none;
}

.pricing-features ul {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 1.5em;
  font-size: 1.1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .pricing-plans {
    padding: 0 20px;
    gap: 20px;
    grid-template-columns: 1fr; /* Stack cards vertically on smaller screens */
  }
  
  .page-title h1 {
    font-size: 2.5rem;
    padding: 0 20px;
  }
  
  .pricing-card h2 {
    font-size: 1.75rem;
  }
  
  .pricing-card p {
    font-size: 1.125rem;
  }
}
